<template>
  <b-form-group :label="$t($attrs['label-text'])">
    <div class="q-mb-md">
      <input
        class="form-control rounded"
        type="text"
        :value="formattedHijriDate"
        @focus="showCalendar = true"
      />
      <div v-if="showCalendar" class="calendar-container">
        <div class="q-mb-md">
          <div class="calendar-header">
            <button @click="prevMonth">&lt;</button>
            <span>{{ currentMonthName }} {{ currentYear }}</span>
            <button @click="nextMonth">&gt;</button>
          </div>
          <div class="calendar-body">
            <div class="day-names">
              <div v-for="day in dayNames" :key="day">{{ day }}</div>
            </div>
            <div class="dates">
              <div
                v-for="day in leadingDays"
                :key="'lead-' + day"
                class="empty"
              ></div>
              <div
                v-for="day in daysInMonth"
                :key="day"
                :class="{ today: isToday(day), selected: isSelected(day) }"
                @click="selectDate(day)"
              >
                {{ day }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </b-form-group>
</template>

<script>
import moment from 'moment-hijri';

export default {
  props: {
    value: {
      type: String,
      required: false,
      default: '',
    },
    dir: {
      type: String,
    },
    inputdate: {
      type: String,
    },
  },
  watch: {
    inputdate: {
      handler(newVal) {
        if (this.isSelectedHijri) {
          this.isSelectedHijri = false;
          return;
        }
        if (newVal) {
          this.selectedDate = moment(newVal);
          this.$emit('updateHijri', this.selectedDate.format('iD iMMMM iYYYY'));
        }
      },
    },
  },
  data() {
    return {
      showCalendar: false,
      currentDate: moment(),
      selectedDate: null,
      isSelectedHijri: false,
    };
  },
  computed: {
    currentMonth() {
      return this.currentDate.iMonth();
    },
    currentYear() {
      return this.currentDate.iYear();
    },
    currentMonthName() {
      return this.currentDate.format('iMMMM');
    },
    daysInMonth() {
      return this.currentDate.daysInMonth();
    },
    leadingDays() {
      const firstDayOfMonth = moment(this.currentDate)
        .startOf('iMonth')
        .day();
      return Array.from({ length: firstDayOfMonth });
    },
    dayNames() {
      return moment.weekdaysShort();
    },
    formattedHijriDate() {
      if (this.selectedDate) {
        return this.selectedDate.format('iD iMMMM iYYYY');
      }
      return '';
    },
  },
  mounted() {
    document.addEventListener('click', this.closeCalendar);
  },
  methods: {
    prevMonth() {
      this.currentDate = moment(this.currentDate).subtract(1, 'iMonth');
    },
    nextMonth() {
      this.currentDate = moment(this.currentDate).add(1, 'iMonth');
    },
    isToday(day) {
      const today = moment();
      return (
        this.currentDate.iYear() === today.iYear() && this.currentDate.iMonth() === today.iMonth() && day === today.iDate()
      );
    },
    isSelected(day) {
      if (!this.selectedDate) return false;
      return (
        this.currentDate.iYear() === this.selectedDate.iYear() && this.currentDate.iMonth() === this.selectedDate.iMonth() && day === this.selectedDate.iDate()
      );
    },
    selectDate(day) {
      this.isSelectedHijri = true;
      this.selectedDate = moment(this.currentDate).iDate(day);
      this.showCalendar = false;
      this.emitDateSelection(); // Emit selected date outside of component
    },
    closeCalendar(event) {
      // Check if the click is outside the calendar container
      if (!this.$el.contains(event.target)) {
        this.showCalendar = false;
      }
    },
    emitDateSelection() {
      // Emit the selected date to parent component via an event
      this.$emit('update:value', this.selectedDate.format('iD iMMMM iYYYY'));
      this.$emit(
        'change',
        moment(this.selectedDate)
          .locale('en')
          .format('YYYY-MM-DD', 'en')
      );
      this.$emit('updateHijri', this.selectedDate.format('iD iMMMM iYYYY'));
    },
  },
  beforeDestroy() {
    // Remove event listener when component is destroyed
    document.removeEventListener('click', this.closeCalendar);
  },
};
</script>

<style>
.hijri-datepicker {
  position: relative;
  display: inline-block;
}

.calendar-container {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  border: 1px solid #ccc;
  background: #fff;
}

.hijri-calendar {
  border: 1px solid #ccc;
  display: inline-block;
  font-family: Arial, sans-serif;
}

.calendar-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: #f7f7f7;
}

.calendar-body {
  padding: 10px;
}

.day-names,
.dates {
  display: flex;
  flex-wrap: wrap;
}

.day-names div,
.dates div {
  width: 14.28%;
  text-align: center;
  padding: 5px 0;
}

.dates div {
  cursor: pointer;
}

.dates .empty {
  background-color: #f7f7f7;
}

.dates .today {
  background-color: #d3f9d8;
}

.dates .selected {
  background-color: #b3e0f2;
}
</style>
