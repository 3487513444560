<template>
  <b-card>
    <g-form @submit="checkIdentityNumber">
      <b-row>
        <b-col md="4">
          <b-button
            :variant="file.name ? 'success' : 'primary'"
            data-action-type="new"
            class="mr-1 btn-sm mb-2"
            @click="
              (v) => {
                openModal();
              }
            "
          >
            {{ file.name ? $t("hasAttachMents") : $t("attachFile") }}
            <feather-icon
              icon="UploadIcon"
              size="15"
              class="ml-25"
            />
          </b-button>
          <a
            v-if="selectedItem.attachmentUrl"
            class="mr-1 btn-sm btn btn-success"
            :href="selectedItem.attachmentUrl"
            @click.prevent="downloadItem(selectedItem)"
          >
            {{ $t("attachedFile") }}
            <feather-icon icon="DownloadIcon" size="15" class="ml-25" />
          </a>
        </b-col>

        <b-col cols="12" class="d-flex justify-content-center mb-2">
          <!-- media -->
          <b-media no-body>
            <b-media-aside>
              <b-link>
                <b-img
                  ref="previewEl"
                  rounded
                  height="80"
                  width="80"
                  :src="url"
                />
              </b-link>
              <!--/ avatar -->
            </b-media-aside>

            <b-media-body class="mt-75 ml-75">
              <!-- upload button -->
              <b-button
                variant="primary"
                size="sm"
                class="mb-75 mr-75"
                @click="$refs.imgupload.$el.click()"
              >
                {{ $t("change") }}
              </b-button>
              <b-form-file
                @change="onFileChanged"
                ref="imgupload"
                accept=".jpg, .png, .gif"
                :hidden="true"
                plain
              />
              <!--/ upload button -->
            </b-media-body>
          </b-media>
          <!--/ media -->
        </b-col>
      </b-row>

      <!-- static data -->
      <b-row>
        <!-- code  -->
        <b-col md="4">
          <!-- code  -->
          <g-field
            id="code"
            type="number"
            :value.sync="selectedItem.code"
            name="code"
            label-text="code"
            disabled
          />
        </b-col>
        <!-- arabic name  -->
        <b-col md="4">
          <!-- arabicName  -->
          <g-field
            :value.sync="selectedItem.arabicName"
            rules="required"
            label-text="arabicName"
            @change="(val) => {
              nameChanged(val.trim())
            }"
          />
          <label
            v-if="helper.isNameUsed"
            class="student-hint"
            style="color: #ea5455;"
          >
            {{ $t('studentNameMustBeUnique') }}
          </label>
        </b-col>
        <b-col md="4">
          <g-field
            ref="englishName"
            :value.sync="selectedItem.englishName"
            label-text="englishName"
          />
        </b-col>
      </b-row>
      <b-tabs content-class="pt-1" pills fill v-model="tabIndex">
        <b-tab :title="$t('personalData')" :active="tabIndex === 0">
          <b-card border-variant="primary">
            <b-row>
              <b-col md="4">
                <!-- birthDate  -->
                <g-picker
                  :value.sync="selectedItem.birthDate"
                  label-text="birthDate"
                  name="birthDate"
                  @change="
                    (v) => {
                      birthDatetoHijriDate(v);
                      this.isFromMeladyToHijri = true
                    }
                  "
                />
              </b-col>
              <b-col md="4">
                <hijriDate
                  :value.sync="hijriDateProp"
                  label-text="hijriBirthDate"
                  name="hijriBirthDate"
                  :inputdate="hijriDateProp"
                  @change="hijriBirthDateValue"
                  @updateHijri="updateHijriDate"
                />
              </b-col>
              <b-col md="4">
                <!-- registrationDate  -->
                <g-picker
                  :value.sync="selectedItem.registrationDate"
                  label-text="registrationDate"
                  name="registrationDate"
                  @change="
                    (v) => {
                      registrationDatetoHijriDate(v);
                      this.isFromMeladyToHijriRegister = true
                    }
                  "
                />
              </b-col>
            </b-row>
            <b-row>
              <b-col md="4">
                <hijriDate
                  :value.sync="hijriRegistrationDateProp"
                  label-text="hijriRegistrationDate"
                  name="hijriRegistrationDate"
                  :inputdate="hijriRegistrationDateProp"
                  @change="hijriRegistrationDateValue"
                  @updateHijri="updateHijriRegistrationDate"
                />
              </b-col>
              <!-- identityNumber -->
              <b-col md="4">
                <g-field
                  :value.sync="selectedItem.identityNumber"
                  label-text="idNumber"
                />
              </b-col>
              <b-col md="4">
                <g-field
                  id="phone"
                  rules="max:10|min:10|required"
                  :value.sync="selectedItem.mobileNumber"
                  name="scalesCode"
                  type="number"
                  label-text="phone"
                  @change="(val) => {
                    mobileChanged(val);
                  }"
                />
                <label
                  v-if="helper.studentsWithSameMobile.length > 0"
                  class="student-hint"
                  style="color: #ff9f43;"
                >
                  <feather-icon
                    icon="AlertTriangleIcon"
                    class="mr-50 b-avatar-sm"
                  />
                  {{ $t('studentsExistsWithSameMobileAndTheyAre', { students: helper.studentsWithSameMobile.join(' ، ') }) }}
                </label>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="4">
                <!-- noorId  -->
                <g-field
                  :value.sync="selectedItem.noorId"
                  label-text="noorId"
                />
              </b-col>

              <b-col md="4">
                <b-form-group>
                  <g-field
                    field="select"
                    label="arabicName"
                    label-text="accountingLinkage"
                    name="accountId"
                    :value.sync="selectedItem.accountId"
                    :dir="isRight ? 'rtl' : 'ltr'"
                    :options="accounts"
                  />
                </b-form-group>
              </b-col>

              <b-col md="4">
                <b-form-group>
                  <label
                    style="font-size: 14px; margin-bottom: 7px"
                    for="guardian"
                  >
                    {{ $t("guardian") }}
                    <feather-icon
                      v-b-tooltip.hover="$t('guardian-new')"
                      icon="PlusIcon"
                      class="clickable"
                      @click="
                        () => {
                          addExtrnalGuardian();
                        }
                      "
                    />
                  </label>
                  <g-field
                    :value.sync="selectedItem.guardianId"
                    :dir="isRight ? 'rtl' : 'ltr'"
                    field="select"
                    name="guardians"
                    :options="guardians"
                    label="arabicName"
                  />
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group>
                  <g-field
                    :value.sync="selectedItem.guardianRelationship"
                    :dir="isRight ? 'rtl' : 'ltr'"
                    label-text="guardianRelationship"
                    field="select"
                    name="guardianRelationship"
                    :options="guardianRelationships"
                    label="arabicName"
                    :disabled="!selectedItem.guardianId"
                  />
                </b-form-group>
              </b-col>
              <b-col
                v-if="selectedItem.guardianRelationship === 'other'"
                md="4"
              >
                <!-- guardianRelationship  -->
                <g-field
                  :value.sync="selectedItem.guardianOtherRelationship"
                  label-text="guardianRelationship"
                />
              </b-col>
              <b-col md="4">
                <b-form-group>
                  <g-field
                    :value.sync="selectedItem.gender"
                    :dir="isRight ? 'rtl' : 'ltr'"
                    label-text="gender"
                    field="select"
                    name="gender"
                    :options="genders"
                    label="arabicName"
                  />
                </b-form-group>
              </b-col>

              <b-col md="4">
                <!-- noorId  -->
                <g-field
                  :value.sync="selectedItem.nameOfTheLastSchool"
                  label-text="nameOfTheLastSchool"
                />
              </b-col>

              <!-- nationality -->
              <b-col md="4">
                <b-form-group>
                  <g-field
                    field="select"
                    label="arabicName"
                    label-text="nationality"
                    name="nationality"
                    rules="required"
                    :value.sync="selectedItem.nationalityId"
                    :dir="isRight ? 'rtl' : 'ltr'"
                    :options="nationalities"
                  />
                </b-form-group>
              </b-col>

              <!-- studentStatus -->
              <b-col md="4">
                <b-form-group>
                  <g-field
                    field="select"
                    label="arabicName"
                    label-text="studentStatus"
                    name="studentStatus"
                    :value.sync="selectedItem.studentStatusId"
                    :dir="isRight ? 'rtl' : 'ltr'"
                    :options="studentStatuses"
                  />
                </b-form-group>
              </b-col>
              <!-- previous balance  -->
              <b-col md="4">
                <label
                  style="font-size: 14px;margin-bottom: 7px;"
                  for="previousBalance"
                >
                  {{ $t('previousBalance') }}
                  <feather-icon
                    v-b-tooltip.hover="$t('previousBalanceHint')"
                    icon="InfoIcon"
                    class="mx-25 clickable"
                  />
                </label>
                <b-form-group>
                  <g-field
                    label="previousBalance"
                    name="previousBalance"
                    :value.sync="previousBalance"
                  />
                </b-form-group>
              </b-col>
              <!-- discount -->
               <b-col md="4">
                <b-form-group>
                  <g-field
                    field="select"
                    label="arabicName"
                    label-text="discount"
                    name="discount"
                    :value.sync="selectedItem.discountId"
                    :dir="isRight ? 'rtl' : 'ltr'"
                    :options="studentDiscounts"
                    :filterFn="(studentDiscounts) => studentDiscounts.valueType === 'percentage'"
                  />
                </b-form-group>
              </b-col>
              <b-col md="4">
                <!-- birthDate  -->
                <g-picker
                  :value.sync="selectedItem.studyStartDate"
                  label-text="studyStartDate"
                  name="studyStartDate"
                  @change="
                    (v) => {
                    }
                  "
                />
              </b-col>
              <b-col
                md="4"
                class="mt-1"
              >
                <h6>
                  <strong>{{ $t("transferRegistration") }}</strong>
                </h6>
                <!-- <b-form-group> -->
                <b-form-radio-group
                  v-model="selectedItem.hasBus"
                  button-variant="outline-primary"
                  :options="optionsRadio"
                  size="md"
                  class="mt-1"
                  name="radios-btn-default"
                />
                <!-- </b-form-group> -->
              </b-col>

              <b-col
                v-if="selectedItem.hasBus === true"
                md="4"
              >
                <g-field
                  :value.sync="selectedItem.detailedAddress"
                  label-text="detailedAddress"
                />
              </b-col>
              <b-col md="4" v-if="selectedItem.hasBus === true">
                <b-form-group>
                  <g-field
                    :value.sync="selectedItem.busSubscriptionCategory"
                    :dir="isRight ? 'rtl' : 'ltr'"
                    label-text="busSubscriptionCategory"
                    field="select"
                    name="busSubscriptionCategory"
                    :options="busSubscriptionCategory"
                    label="arabicName"
                  />
                </b-form-group>
              </b-col>
            </b-row>

            <b-row class="mt-2">
              <b-col md="3">
                <label
                  style="font-size: 14px; margin-bottom: 7px"
                  for="mobileNumberTextable"
                >
                  {{ $t("mobileNumberTextable") }}
                </label>
                <b-form-group>
                  <b-form-checkbox
                    v-model="selectedItem.mobileNumberTextable"
                    class="custom-control-primary"
                  >
                  </b-form-checkbox>
                </b-form-group>
              </b-col>
              <b-col md="3">
                <label
                  style="font-size: 14px; margin-bottom: 7px"
                  for="registeredInNoor"
                >
                  {{ $t("registeredInNoor") }}
                </label>
                <b-form-group>
                  <b-form-checkbox
                    v-model="selectedItem.registeredInNoor"
                    class="custom-control-primary"
                  >
                  </b-form-checkbox>
                </b-form-group>
              </b-col>

              <b-col md="3">
                <label
                  style="font-size: 14px; margin-bottom: 7px"
                  for="completedPapers"
                >
                  {{ $t("completedPapers") }}
                </label>
                <b-form-group>
                  <b-form-checkbox
                    v-model="selectedItem.completedPapers"
                    class="custom-control-primary"
                  >
                  </b-form-checkbox>
                </b-form-group>
              </b-col>

              <b-col md="3">
                <label
                  style="font-size: 14px; margin-bottom: 7px"
                >
                  {{ $t("studentIsSuspended") }}
                </label>
                <b-form-group>
                  <b-form-checkbox
                    v-model="selectedItem.isSuspended"
                    :disabled="!isHavePermissionToSuspend"
                    class="custom-control-primary"
                    @change="(val) => {
                      if (val) selectedItem.suspensionDate = getDate();
                    }"
                  />
                </b-form-group>
              </b-col>
              <!-- suspensionDate  -->
              <b-col
                v-if="selectedItem.isSuspended && isHavePermissionToSuspend"
                md="4"
              >
                <g-picker
                  :value.sync="selectedItem.suspensionDate"
                  :disabled="!isHavePermissionToSuspend"
                  label-text="suspensionDate"
                  name="suspensionDate"
                />
              </b-col>
              <b-col
                v-if="selectedItem.isSuspended && !isHavePermissionToSuspend"
                md="4"
              >
                <!-- suspensionDate for readonly data  -->
                <g-field
                  :value="getDate(selectedItem.suspensionDate)"
                  disabled
                  label-text="suspensionDate"
                />
              </b-col>
            </b-row>
            <b-row>
              <!-- notes -->
              <b-col
                md="12"
                class="mt-1"
              >
                <b-form-group :label="$t('notes')">
                  <b-form-textarea
                    id="textarea"
                    v-model="selectedItem.notes"
                    label-text="Notes"
                    rows="3"
                    max-rows="6"
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </b-card>
        </b-tab>
        <!-- stageData -->
        <b-tab :title="$t('stageData')" :active="tabIndex === 1">
          <b-card border-variant="primary">
            <b-row>
              <!-- stageId -->
              <b-col md="4">
                <b-form-group>
                  <g-field
                    v-model="selectedItem.stageId"
                    :dir="isRight ? 'rtl' : 'ltr'"
                    field="select"
                    name="stages"
                    :options="stages"
                    :label="isRight ? 'arabicName' : 'englishName'"
                    label-text="stage"
                    @change="
                      () => {
                        this.selectedItem.row = null;
                        this.selectedItem.classroomId = null;
                      }
                    "
                  />
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group>
                  <g-field
                    v-model="selectedItem.row"
                    :dir="isRight ? 'rtl' : 'ltr'"
                    field="select"
                    name="row"
                    :options="rows"
                    :filterFn="(item) => item.stageId === selectedItem.stageId"
                    :label="isRight ? 'arabicName' : 'englishName'"
                    label-text="row"
                    @change="
                      () => {
                        this.selectedItem.classroomId = null;
                      }
                    "
                  />
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group>
                  <g-field
                    v-model="selectedItem.classroomId"
                    :dir="isRight ? 'rtl' : 'ltr'"
                    field="select"
                    name="classRoom"
                    :options="classRooms"
                    :filterFn="(item) => item.row === selectedItem.row"
                    :label="isRight ? 'arabicName' : 'englishName'"
                    label-text="classRoom"
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </b-card>
        </b-tab>

        <b-tab
          :title="$t('Medicalandpsychologicaldataofthestudent')"
          :active="tabIndex === 2"
        >
          <b-card border-variant="primary">
            <b-row>
              <b-col md="4">
                <b-form-group>
                  <g-field
                    :value.sync="selectedItem.religionId"
                    :dir="isRight ? 'rtl' : 'ltr'"
                    label-text="religion"
                    field="select"
                    name="religions"
                    :options="religions"
                    label="arabicName"
                  />
                </b-form-group>
              </b-col>

              <b-col md="4">
                <g-field
                  :value.sync="selectedItem.numberOfBrothers"
                  label-text="numberOfBrothers"
                />
              </b-col>
              <b-col md="4">
                <g-field
                  :value.sync="selectedItem.childArrangement"
                  label-text="childArrangement"
                />
              </b-col>
            </b-row>
            <b-row>
              <b-col md="4">
                <b-form-group>
                  <g-field
                    :value.sync="selectedItem.childLivesWith"
                    :dir="isRight ? 'rtl' : 'ltr'"
                    label-text="childLivesWith"
                    field="select"
                    name="childLivesWith"
                    :options="childLivesWith"
                    label="arabicName"
                  />
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group>
                  <g-field
                    :value.sync="selectedItem.maritalStatusParentsId"
                    :dir="isRight ? 'rtl' : 'ltr'"
                    label-text="maritalStatusForParents"
                    field="select"
                    name="maritalStatus"
                    :options="maritalStatus"
                    label="arabicName"
                  />
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group>
                  <g-field
                    :value.sync="selectedItem.roleOfGrandparents"
                    :dir="isRight ? 'rtl' : 'ltr'"
                    label-text="roleOfGrandparents"
                    field="select"
                    name="RoleOfGrandparents"
                    :options="roleOfGrandparents"
                    label="arabicName"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="4">
                <g-field
                  :value.sync="selectedItem.childSkillsAndTendencies"
                  label-text="childSkillsAndTendencies"
                />
              </b-col>
              <b-col md="4">
                <g-field
                  :value.sync="selectedItem.participatesInGroupGames"
                  label-text="participatesInGroupGames"
                />
              </b-col>
              <b-col md="4">
                <g-field
                  :value.sync="selectedItem.favoriteSports"
                  label-text="favoriteSports"
                />
              </b-col>
            </b-row>
            <b-row>
              <b-col md="4">
                <g-field
                  :value.sync="selectedItem.favoritePlace"
                  label-text="favoritePlace"
                />
              </b-col>
              <b-col md="4">
                <g-field
                  :value.sync="selectedItem.relationshipWitFriendsAndBrother"
                  label-text="relationshipWitFriendsAndBrother"
                />
              </b-col>
              <b-col md="4">
                <g-field
                  :value.sync="selectedItem.behaviorsWishToGetRidOf"
                  label-text="behaviorsWishToGetRidOf"
                />
              </b-col>
            </b-row>
            <b-row>
              <b-col md="4">
                <g-field
                  :value.sync="selectedItem.firstReactionToBeingPressured"
                  label-text="firstReactionToBeingPressured"
                />
              </b-col>
              <b-col md="4">
                <g-field
                  :value.sync="selectedItem.punishmentOfTheChildWhenWrong"
                  label-text="punishmentOfTheChildWhenWrong"
                />
              </b-col>
              <b-col md="4">
                <g-field
                  :value.sync="selectedItem.wentToAPsychologistOrBehaviorist"
                  label-text="heWentToPsychologistOrBehaviorist"
                />
              </b-col>
            </b-row>
            <b-row>
              <b-col md="4">
                <g-field
                  :value.sync="selectedItem.suffersFromDiseases"
                  label-text="suffersFromDiseases"
                />
              </b-col>

              <b-col md="4">
                <b-form-group>
                  <g-field
                    :value.sync="selectedItem.illnessHistoryInFamily"
                    :dir="isRight ? 'rtl' : 'ltr'"
                    label-text="illnessHistoryInFamily"
                    field="select"
                    name="illnessHistoryInFamily"
                    :options="illnessHistoryInFamily"
                    label="arabicName"
                  />
                </b-form-group>
              </b-col>

              <b-col md="4">
                <b-form-group>
                  <g-field
                    :value.sync="selectedItem.suffersFromAnAllergy"
                    :dir="isRight ? 'rtl' : 'ltr'"
                    label-text="suffersFromAnAllergy"
                    field="select"
                    name="suffersFromAnAllergy"
                    :options="suffersFromAnAllergy"
                    label="arabicName"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="4">
                <g-field
                  :value.sync="selectedItem.academicLevelAndAchievement"
                  label-text="academicLevelAndAchievement"
                />
              </b-col>

              <b-col md="4">
                <b-form-group>
                  <g-field
                    :value.sync="selectedItem.describeChild"
                    :dir="isRight ? 'rtl' : 'ltr'"
                    label-text="describeChild"
                    field="select"
                    name="describeChild"
                    :options="describeChild"
                    label="arabicName"
                  />
                </b-form-group>
              </b-col>

              <b-col md="4">
                <g-field
                  :value.sync="
                    selectedItem.healthReasonThatInterferesWithPerformance
                  "
                  label-text="healthReasonThatInterferesWithPerformance"
                />
              </b-col>
            </b-row>
            <b-row>
              <b-col md="4">
                <g-field
                  :value.sync="
                    selectedItem.receivesTreatmentPeriodicallyOrContinuously
                  "
                  label-text="receivesTreatmentPeriodicallyOrContinuously"
                />
              </b-col>
              <b-col md="4">
                <g-field
                  :value.sync="selectedItem.repetitiveBehaviour"
                  label-text="repetitiveBehaviour"
                />
              </b-col>
              <b-col md="4">
                <g-field
                  :value.sync="selectedItem.firstPersonChildTurnsToWhenAngry"
                  label-text="firstPersonChildTurnsToWhenAngry"
                />
              </b-col>
            </b-row>
          </b-card>
        </b-tab>

        <!-- Services -->
        <!-- <b-tab
          class="d-none"
          :title="$t('servicesAndPayments')"
          :active="tabIndex === 2"
        >
          <b-card border-variant="primary">
            <b-row class="px-2">
              <b-col md="4">
                <label
                  style="font-size: 14px; margin-bottom: 12px"
                  for="mainUnit"
                >
                  {{ $t("services") }}
                </label>
                <b-form-group>
                  <g-field
                    :value.sync="selectedItem.serviceId"
                    :dir="isRight ? 'rtl' : 'ltr'"
                    field="select"
                    :options="services"
                    label="arabicName"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12" class="d-flex justify-content-end mb-50">
                <b-button
                  @click="
                    () => {
                      addServices(selectedItem.serviceId);
                    }
                  "
                  variant="primary"
                >
                  {{ $t("addService") }}
                </b-button>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12" class="medium-window">
                <b-table
                  ref="b-table"
                  :items="selectedItem.services"
                  :fields="servicesTableColumns"
                  perPage="25"
                  small
                  show-empty
                  hover
                  stickyColumn
                  :current-page="currentPage"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="isSortDirDesc"
                  :sort-direction="sortDirection"
                  class="mb-0"
                  :empty-text="$t('noMatchingRecordsFound')"
                >
                  <template #head(actions)>
                    <span></span>
                  </template>
                  <template #cell(serviceFees)="{ item }">
                    <g-field
                      class="mb-0 m-auto text-center"
                      :value.sync="item.serviceFees"
                      type="number"
                      rules="required"
                      :shortDesc="true"
                      size="sm"
                      :style="{ width: '100px' }"
                    />
                  </template>
                  <template #cell(actions)="{ item }">
                    <div class="text-nowrap">
                      <b-button
                        v-b-tooltip.hover.bottom="$t('delete')"
                        variant="flat-danger"
                        size="sm"
                        @click="removeServiceFromTable(item)"
                      >
                        <feather-icon
                          icon="TrashIcon"
                          stroke="red"
                          class="mx-0 clickable danger"
                          :id="`invoice-row-${item.id}-delete-icon`"
                        />
                      </b-button>
                    </div>
                  </template>
                </b-table>
              </b-col>
            </b-row>
          </b-card>
        </b-tab> -->
        <!-- discounts -->
        <!-- <b-tab class="d-none" :title="$t('discounts')" :active="tabIndex === 3">
          <b-card border-variant="primary">
            <b-row class="px-2">
              <b-col md="4">
                <label
                  style="font-size: 14px; margin-bottom: 12px"
                  for="mainUnit"
                >
                  {{ $t("discounts") }}
                </label>
                <b-form-group>
                  <g-field
                    :value.sync="selectedItem.discountId"
                    :dir="isRight ? 'rtl' : 'ltr'"
                    field="select"
                    :options="discounts"
                    label="arabicName"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12" class="d-flex justify-content-end mb-50">
                <b-button
                  @click="
                    () => {
                      addDiscounts(selectedItem.discountId);
                    }
                  "
                  variant="primary"
                >
                  {{ $t("addDiscount") }}
                </b-button>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12" class="medium-window">
                <b-table
                  ref="d-table"
                  :items="selectedItem.discounts"
                  :fields="discountsTableColumns"
                  perPage="25"
                  small
                  show-empty
                  hover
                  stickyColumn
                  :current-page="currentPage"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="isSortDirDesc"
                  :sort-direction="sortDirection"
                  class="mb-0"
                  :empty-text="$t('noMatchingRecordsFound')"
                >
                  <template #head(actions)>
                    <span></span>
                  </template>
                  <template #cell(value)="{ item }">
                    <g-field
                      class="mb-0 m-auto text-center"
                      :value.sync="item.value"
                      type="number"
                      rules="required"
                      :shortDesc="true"
                      size="sm"
                      :style="{ width: '40%' }"
                    />
                  </template>
                  <template #cell(notes)="{ item }">
                    <g-field
                      class="mb-0 m-auto text-center"
                      :value.sync="item.notes"
                      :shortDesc="true"
                      size="sm"
                    />
                  </template>
                  <template #cell(actions)="{ item }">
                    <div class="text-nowrap">
                      <b-button
                        v-b-tooltip.hover.bottom="$t('delete')"
                        variant="flat-danger"
                        size="sm"
                        @click="removeDescountFromTable(item)"
                      >
                        <feather-icon
                          icon="TrashIcon"
                          stroke="red"
                          class="mx-0 clickable danger"
                          :id="`invoice-row-${item.id}-delete-icon`"
                        />
                      </b-button>
                    </div>
                  </template>
                </b-table>
              </b-col>
            </b-row>
          </b-card>
        </b-tab> -->
      </b-tabs>
      <b-row>
        <b-col cols="12">
          <hr />
        </b-col>
        <b-col cols="12" class="d-flex justify-content-end">
          <b-button
            class="mx-1"
            type="submit"
            variant="primary"
            data-action-type="save"
            v-permission="$route.meta.permission"
          >
            {{ selectedItem.id > 0 ? $t("edit") : $t("save") }}
          </b-button>
        </b-col>
      </b-row>
    </g-form>
    <b-modal
      no-close-on-backdrop
      ref="upload-modal"
      cancel-variant="outline-secondary"
      centered
      size="lg"
      hide-footer
      :title="$t('attachFile')"
    >
      <g-form>
        <b-row>
          <b-col cols="12">
            <div>
              <input
                type="file"
                class="excel-upload-input"
                @change="handleFileUpload($event)"
                accept=".pdf, .jpg, .jpeg, .bmp, .png, .doc, .docx, .zip"
              />
              <b-alert
                v-if="file"
                show
                fade
                class="mt-2 text-center"
                variant="success"
              >
                <div class="alert-body">
                  <span>{{ $t("importSuccsefly") }} {{ file.name }}</span>
                </div>
              </b-alert>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <hr />
          </b-col>
          <b-col cols="12" class="d-flex justify-content-end">
            <b-button class="mx-1" variant="primary" @click="closeModal">
              {{ $t("save") }}
            </b-button>
            <b-button
              class="mx-1"
              @click="
                () => {
                  removeAttachment();
                }
              "
              variant="danger"
              data-action-type="delete"
              :disabled="!file"
            >
              {{ $t("cancel") }}
            </b-button>
          </b-col>
        </b-row>
      </g-form>
    </b-modal>
  </b-card>
</template>

<script>

import {
  guardianRelationships,
  genders, childLivesWith,
  roleOfGrandparents, illnessHistoryInFamily,
  suffersFromAnAllergy, describeChild,
  busSubscriptionCategory
} from '@/libs/acl/Lookups';

import saveAs from 'file-saver';
import hijriDate from '@/components/HijriDatePicker.vue';

export default {
  props: ['id'],
  components: {
    hijriDate,
  },
  data() {
    return {
      isFromMeladyToHijri: true,
      isFromMeladyToHijriRegister: true,
      nationalities: [],
      studentDiscounts: [],
      transferAnswer: '',
      tabIndex: 0,
      isValid: true,
      buttonType: '',
      sortBy: 'id',
      totalRows: 0,
      currentPage: 1,
      isTableBusy: false,
      hijriDateProp: '',
      hijriRegistrationDateProp: '',
      perPage: 25,
      filterOn: [],
      isSortDirDesc: false,
      sortDirection: 'asc',
      searchQuery: '',
      codeLengthNotValid: false,
      oldStoreOpeningBalance: 0,
      oldStoreCurrentBalance: 0,
      previousBalance: 0,
      file: '',
      filter: {
        code: '',
        arabicNamePart: '',
        englishNamePart: '',
        OrderClause: '',
      },
      selectedItem: {
        code: '',
        arabicName: '',
        englishName: '',
        hijriBirthDate: '',
        hijriRegistrationDate: '',
        notes: '',
        stageId: null,
        row: null,
        classroomId: null,
        services: [],
        discounts: [],
        hasBus: false,
        guardianId: null,
        isSuspended: false,
        registrationDate: ''
      },
      services: [],
      discounts: [],
      guardians: [],
      religions: [],
      maritalStatus: [],
      accounts: [],
      guardianRelationships: guardianRelationships,
      genders: genders,
      childLivesWith: childLivesWith,
      roleOfGrandparents: roleOfGrandparents,
      describeChild: describeChild,
      illnessHistoryInFamily: illnessHistoryInFamily,
      suffersFromAnAllergy: suffersFromAnAllergy,
      busSubscriptionCategory: busSubscriptionCategory,
      rows: [],
      stages: [],
      classRooms: [],
      studentStatuses: [],
      url: '',
      totalCurrentBalance: 0,
      totalOpeningBalance: 0,
      helper: {
        currentStudentClassId: 0,
        currentStudentName: '',
        currentStudentMobile: '',
        isNameUsed: false,
        studentsWithSameMobile: []
      }
    };
  },
  computed: {
    servicesTableColumns() {
      return [
        {
          key: 'code',
          label: this.$t('code'),
          sortable: true,
        },
        {
          key: this.isRight ? 'arabicName' : 'englishName',
          label: this.$t('name'),
          sortable: true,
        },
        {
          key: 'serviceFees',
          label: this.$t('serviceFees'),
          sortable: true,
          type: 'number'
        },
        {
          key: 'actions',
          sortable: false
        },
      ];
    },
    optionsRadio() {
      return [
        { text: this.$t('yes'), value: true },
        { text: this.$t('no'), value: false },
      ];
    },
    discountsTableColumns() {
      return [
        {
          key: this.isRight ? 'arabicName' : 'englishName',
          label: this.$t('name'),
          sortable: true,
        },
        {
          key: 'value',
          label: this.$t('value'),
          sortable: true,
          type: 'number'
        },
        {
          key: 'notes',
          label: this.$t('notes'),
          sortable: false,
        },
        { key: 'actions' },
      ];
    },

    isHavePermissionToSuspend() {
      return this.profile.isAdmin || this.profile.permissions.includes('suspendStudents');
    }
  },

  mounted() {
    this.url = 'Student_default_image.jpg';
    this.selectedItem.registrationDate = this.today;
    this.getNationalities();
    this.getDiscounts();
    this.loadData();
    if (this.id > 0) {
      this.getData();
    }
  },

  methods: {
    hijriBirthDateValue(data) {
      this.isFromMeladyToHijri = false;
      this.selectedItem.birthDate = this.getDate(data);
    },
    updateHijriDate(e) {
      this.selectedItem.hijriBirthDate = e
    },
    updateHijriRegistrationDate(e) {
      this.selectedItem.hijriRegistrationDate = e
    },
    hijriRegistrationDateValue(date) {
      this.isFromMeladyToHijriRegister = false;
      this.selectedItem.registrationDate = this.getDate(date);
    },
    addExtrnalGuardian() {
      var myWindow = window.open('/#/Guardians/new', '', `width=${window.screen.availWidth}height=${window.screen.availHeight}`);
      var popupTick = setInterval(function () {
        if (myWindow.closed) {
          clearInterval(popupTick);
          window.location.reload();
          this.get({ url: 'guardians' }).then((data) => {
            this.guardians = data;
          });
        }
      }, 500);
    },
    getNationalities() {
      this.get({ url: 'nationalities' }).then((data) => {
        this.nationalities = data;
      });
    },
    getDiscounts() {
      this.get({ url: 'discounts' }).then((data) => {
        this.studentDiscounts = data;
      });
    },
    downloadItem(item) {
      const path = item.attachmentUrl.split('.')[1];
      saveAs(`${this.baseUrl}${item.attachmentUrl}`, `attached-file.${path}`);
    },
    birthDatetoHijriDate(date) {
      this.convertDateToHijri(date);
    },
    registrationDatetoHijriDate(date) {
      this.convertRegistrationDateToHijri(date);
    },
    loadData() {
      this.get({ url: 'accounts' }).then((data) => {
        this.accounts = data.filter((acc) => acc.type === 'sub')
      });
      this.get({ url: 'services' }).then((data) => {
        this.services = data;
      });
      this.get({ url: 'Rows/getRowsLookupWithEducationlIds' }).then((data) => {
        this.rows = data;
      });
      this.get({ url: 'discounts' }).then((data) => {
        this.discounts = data;
      });
      this.get({ url: 'guardians' }).then((data) => {
        this.guardians = data;
      });
      this.get({ url: 'Religions' }).then((data) => {
        this.religions = data;
      });
      this.get({ url: 'MaritalStatuses' }).then((data) => {
        this.maritalStatus = data;
      });
      this.get({ url: 'Stages' }).then((data) => {
        this.stages = data;
      });
      this.get({ url: 'Classrooms/getClassroomsLookupWithEducationlIds' }).then((data) => {
        this.classRooms = data;
      });
      this.get({ url: 'StudentStatuses' }).then((data) => {
        this.studentStatuses = data;
      });
    },
    getData() {
      this.get({ url: 'students', id: this.id }).then((data) => {
        this.selectedItem = data;
        this.helper.currentStudentClassId = data.classroomId;
        this.helper.currentStudentName = data.arabicName;
        this.helper.currentStudentMobile = data.mobileNumber;
        this.setPreviousBalance(data);
        this.url = this.selectedItem.imageUrl
          ? `${this.domain}${this.selectedItem.imageUrl}`
          : 'Student_default_image.jpg';
      });
    },
    closeModal() {
      this.$refs['upload-modal'].hide();
    },
    openModal() {
      this.$refs['upload-modal'].show();
    },
    removeAttachment() {
      this.selectedItem.attachmentFileExtension = '';
      this.selectedItem.attachmentBase64Content = '';
      this.selectedItem.attachmentFileName = '';
      this.file = '';
      this.closeModal();
    },
    onFileChanged(event) {
      const file = event.target.files[0];
      this.selectedItem.imageFileExtension = file.name.split('.').pop();
      this.toBase64(file)
        .then((file1) => {
          this.selectedItem.imageBase64Content = file1.split(',').pop();
        });
      this.url = URL.createObjectURL(file);
    },
    toBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },
    handleFileUpload(event) {
      this.file = event.target.files[0];
      const fileSize = this.file.size / 1024 / 1024;
      this.selectedItem.attachmentFileExtension = this.file.name.split('.').pop();
      this.toBase64(this.file).then((file1) => {
        this.selectedItem.attachmentBase64Content = file1.split(',').pop();
      });
      this.selectedItem.attachmentFileName = URL.createObjectURL(this.file);
      if (!this.isValidType(this.file)) {
        this.file = '';
        this.selectedItem.attachmentFileExtension = '';
        this.selectedItem.attachmentBase64Content = '';
        this.selectedItem.attachmentFileName = '';
        this.doneAlert({ text: this.$t('Only supports upload .pdf, .jpg, .jpeg, .bmp, .png, .doc, .docx, .zip suffix files'), type: 'error' });
        this.file = '';
        return false
      }
      if (this.isValidType(this.file) && fileSize > 5) {
        this.file = '';
        this.selectedItem.attachmentFileExtension = '';
        this.selectedItem.attachmentBase64Content = '';
        this.selectedItem.attachmentFileName = '';
        this.doneAlert({ text: this.$t('fileSizeExceedsFiveMiB'), type: 'error' });
        this.file = '';
        return false
      }
    },
    isValidType(file) {
      return /\.(pdf|jpg|jpeg|bmp|png|doc|docx|zip)$/.test(file.name)
    },

    addServices(id) {
      let errorMsg = '';
      if (this.selectedItem.services.find((v) => v.serviceId === id)) errorMsg = this.$t('canNotRepeatTheSameServices');
      if (errorMsg) {
        this.doneAlert({
          text: errorMsg,
          type: 'error',
        });
      } else {
        const res = this.services.find((v) => v.id === this.selectedItem.serviceId);
        this.serviceData = res
        this.serviceData.serviceId = res.id;
        this.serviceData.originalServiceFees = res.serviceFees;
        this.selectedItem.services.push({ ...this.serviceData });
      }
      return false;
    },
    addDiscounts(id) {
      let errorMsg = '';
      if (this.selectedItem.discounts.find((v) => v.discountId === id)) errorMsg = this.$t('canNotRepeatTheSameDiscounts');
      if (errorMsg) {
        this.doneAlert({
          text: errorMsg,
          type: 'error',
        });
      } else {
        const res = this.discounts.find((v) => v.id === this.selectedItem.discountId);
        this.discountData = res
        this.discountData.discountId = res.id;
        this.discountData.originalValue = res.value;
        this.selectedItem.discounts.push({ ...this.discountData });
      }
      return false;
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    refreshTable() {
      this.$refs.itemstable.refresh();
    },
    registrationDateCheck() {
      if (!this.selectedItem.registrationDate) {
        this.doneAlert({ text: this.$t('registrationDateIsRequired'), type: 'error' });
        return false;
      }
      return true;
    },
    birthDateCheck() {
      if (!this.selectedItem.birthDate) {
        this.doneAlert({ text: this.$t('birthDateIsRequired'), type: 'error' });
        return false;
      }
      return true;
    },
    checkStages() {
      if (this.selectedItem.stageId === null || this.selectedItem.row === null || this.selectedItem.classroomId === null) {
        this.doneAlert({ text: this.$t('stagesIsRequired'), type: 'error' });
        return false;
      }
      return true;
    },
    checkClasses() {
      const selectedClass = this.classRooms.find((val) => val.id === this.selectedItem.classroomId);
      const isClassComplete = selectedClass.studentsCount === selectedClass.existStudentsCount
      const isStudentInHisClass = this.helper.currentStudentClassId === this.selectedItem.classroomId
      if ((!isStudentInHisClass || this.selectedItem.id === 0) && isClassComplete) {
        this.doneAlert({ text: this.$t('classroomExceedLimit', { class: selectedClass.arabicName }), type: 'warning', timer: 5000 });
        return false;
      }
      return true;
    },
    checkStudentNumber() {
      if (!this.selectedItem.mobileNumber) {
        this.doneAlert({ text: this.$t('StudentMobileIsRequired'), type: 'error' });
        return false;
      }
      return true;
    },
    checkGuardianRelationship() {
      if (this.selectedItem.guardianId && !this.selectedItem.guardianRelationship) {
        this.doneAlert({ text: this.$t('guardianRelationshipIsMandtaory'), type: 'error' });
        return false;
      }
      return true;
    },
    checkGurdain() {
      if (this.selectedItem.guardianId === null) {
        this.doneAlert({ text: this.$t('gurdainIsRequired'), type: 'error' });
        return false;
      }
      return true;
    },
    checkNameUniqueness() {
      return this.helper.isNameUsed;
    },
    checkIdentityNumber() {
      if (!this.selectedItem.identityNumber) {
        this.confirmAction(
          {
            text: this.$t('theStudentWillBeAddedWithoutIdentityNumber'),
            confirmText: this.$t('yes,sure')
          },
          () => {
            this.save();
          }
        );
      } else {
        this.save();
      }
  },
    setPreviousBalance(item) {
      if (item.prevDebit > 0) {
        this.previousBalance = item.prevDebit;
      } else if (item.prevCredit > 0) {
        this.previousBalance = item.prevCredit * -1;
      } else {
        this.previousBalance = 0;
      }
    },

    getCreditOrDebitValue(prevBalance) {
      if (prevBalance > 0) {
        this.selectedItem.prevDebit = prevBalance;
        this.selectedItem.prevCredit = 0;
      } else if (prevBalance < 0) {
        this.selectedItem.prevDebit = 0;
        this.selectedItem.prevCredit = prevBalance * -1;
      } else {
        this.selectedItem.prevDebit = 0;
        this.selectedItem.prevCredit = 0;
      }
    },

    nameChanged(newName) {
      if (!newName || (this.selectedItem.id > 0 && this.helper.currentStudentName === newName)) {
        this.helper.isNameUsed = false;
      } else {
        this.get({ url: `Students/IsStudentNameUsed?studentName=${newName}` }).then((isUsed) => {
          this.helper.isNameUsed = isUsed;
        });
      }
    },
    mobileChanged(newMobile) {
      if (newMobile.length !== 10 || (this.selectedItem.id > 0 && this.helper.currentStudentMobile === newMobile)) {
        this.helper.studentsWithSameMobile = [];
      } else {
        this.get({ url: `Students/GetStudentsWithSameMobileNumber?mobileNumber=${newMobile}` }).then((data) => {
          this.helper.studentsWithSameMobile = data;
        });
      }
    },

    save() {
      if (this.checkNameUniqueness()) return;
      if (!this.checkGuardianRelationship()) return;
      if (!this.checkStudentNumber()) return;
      // if (!this.checkGurdain()) return;
      if (!this.registrationDateCheck()) return;
      if (!this.birthDateCheck()) return;
      if (!this.checkStages()) return;
      if (!this.checkClasses()) return;
      this.getCreditOrDebitValue(this.previousBalance);

      if (this.selectedItem.id > 0) {
        this.update({
          url: 'students',
          data: this.selectedItem,
          id: this.selectedItem.id,
        })
          .then(() => {
            this.doneAlert({ text: this.$t('updatedSuccessfully') });
            this.$router.push({ name: 'students-list' });
          });
      } else {
        this.create({
          url: 'students',
          data: this.selectedItem,
        })
          .then(() => {
            this.doneAlert({ text: this.$t('savedSuccessfully') });
            this.$router.push({ name: 'students-list' });
          });
      }
    },
    removeServiceFromTable(service) {
      this.selectedItem.services = this.selectedItem.services.filter((item) => item !== service);
    },
    removeDescountFromTable(discount) {
      this.selectedItem.discounts = this.selectedItem.discounts.filter((item) => item !== discount);
    },
    itemsSearchProvider(ctx, callback) {
      const {
        currentPage, perPage, sortBy, sortDesc
      } = ctx;
      this.filter.OrderClause = this.orderQuery(sortBy, sortDesc);
      let params = `?pageNumber=${currentPage}&pageSize=${perPage}&`;

      params += this.getFilterObj(this.filter);

      this.isTableBusy = true;
      this.get({ url: `students${params}` })
        .then(({ data, totalCount }) => {
          this.isTableBusy = false;
          this.totalRows = totalCount;
          callback(data);
        })
        .catch(() => {
          this.isTableBusy = false;
          callback([]);
        });
      return null;
    },
    convertDateToHijri(dateTime) {
      if (this.isFromMeladyToHijri) {
       this.hijriDateProp = dateTime;
      }
      },
    convertRegistrationDateToHijri(dateTime) {
      if (this.isFromMeladyToHijriRegister) {
      this.hijriRegistrationDateProp = dateTime;
      }
    },
  }

};
</script>

<style>
.resize_img {
  width: 10%;
  border-radius: 10%;
  margin-left: 50%;
  margin-right: auto;
}

.student-hint {
  display: block;
  margin-top: -11px;
  margin-bottom: 15px;
  font-size: 12px;
}
</style>
